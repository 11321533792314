export default [
    {
        path: 'responsavel',
        name: 'ResponsavelPage',
        meta: {title: "Escolha"},
        component: () => import('@pages/Academico/ResponsavelPage.vue')
    },
    {
        path: 'academico',
        name: 'AcademicoHome',
        meta: {title: "Início"},
        component: () => import('@pages/Academico/Home.vue')
    },
    {
        path: 'academico/rematricula',
        name: 'RematriculaList',
        meta: {title: "Rematrículas"},
        component: () => import('@pages/Academico/RematriculaList.vue')
    },
    {
        path: 'academico/rematricula/create/:curso_id/:matricula_id',
        props: true,
        name: 'RematriculaCreate',
        meta: {title: "Nova Rematrícula"},
        component: () => import('@pages/Academico/RematriculaCreate.vue')
    },
    {
        path: 'academico/matricula/:id',
        props: true,
        name: 'CursoMatriculaList',
        meta: {title: "Rematrículas"},
        component: () => import('@pages/Academico/CursoMatriculaList.vue')
    },
    {
        path: 'academico/cadastro',
        name: 'AcademicoInfo',
        meta: {title: "Cadastro"},
        component: () => import('@pages/Academico/Info.vue')
    },
    {
        path: 'academico/boletim',
        name: 'BoletimList',
        meta: {title: "Boletim"},
        component: () => import('@pages/Academico/BoletimList.vue')
    },
    {
        path: 'academico/boletim/show/:id',
        props: true,
        name: 'BoletimShow',
        meta: {title: "Visualizar Boletim"},
        component: () => import('@pages/Academico/BoletimShow.vue')
    },
    {
        path: 'academico/calendario',
        name: 'AcademicoCalendario',
        meta: {title: "Calendário"},
        component: () => import('@pages/Academico/Calendario.vue')
    },
    {
        path: 'academico/ocorrencia',
        name: 'AcademicoOcorrencia',
        meta: {title: "Ocorrências"},
        component: () => import('@pages/Academico/Ocorrencia.vue')
    },
    {
        path: 'academicocomunicado',
        name: 'AcademicoComunicado',
        meta: {title: "Comunicados"},
        component: () => import('@pages/Academico/Comunicado.vue')
    },
    {
        path: 'aluno/cadastro',
        name: 'AlunoCadastro',
        meta: {title: "Meu Cadastro"},
        component: () => import('@pages/Aluno/AlunoCadastro.vue')
    },
    {
        path: 'aluno/senha/:id',
        props: true,
        name: 'AlunoSenhaEdit',
        meta: {title: "Alterar Senha"},
        component: () => import('@pages/Aluno/AlunoSenhaEdit.vue')
    },
    {
        path: 'saude/edit/:aluno_id',
        props: true,
        name: 'SaudeEdit',
        meta: {title: "Ficha de Saúda"},
        component: () => import('@pages/Saude/SaudeEdit.vue')
    },
]
