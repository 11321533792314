<template>
    <v-col :cols="sizec" :md="size" class="infoBox">
        <label>{{ label }}</label>
        <p :style="{ 'font-size': `${fontsize}px` }"><slot/></p>
    </v-col>
</template>

<script>
import moment from 'moment'

export default{
    props: {
        label: {
            type: String,
            default: ''
        },
        size:{
            type: String,
            default: '12'
        },
        sizec: {
            type: String,
            default: null,
        },
        text: {
            type: [String, Number],
            default: ''
        },
        /* mask: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'text'
        },*/
        fontsize: {
            type: String,
            default: '13'
        },
    },
    computed: {
        getText(){
            let newText = this.text

            if(this.text) {
                /*switch (this.type) {
                    case 'date' :
                        newText = moment(this.text).format('DD/MM/YYYY')
                        break
                    case 'datetime' :
                        newText = moment(this.text).format('DD/MM/YYYY - HH:mm')
                        break
                    case 'sexo' :
                        newText = this.text === '2' ? 'feminino' : 'masculino'
                        break
                    case 'religiao' :
                        newText = this.$listaReligiao.find(item => item.id === this.text).nome
                        break
                }*/
            }

            return newText
        }
    },
}
</script>

<style scoped>
    .infoBox{
        padding-bottom: 0;
    }
    label{
        font-size: 12px;
        font-weight: bold;
        color: #111;
    }
    p{
        font-weight: normal;
        color: #333;
        margin-bottom: 0;
    }
</style>
