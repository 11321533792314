export default [
    {
        path: 'conta',
        name: 'ContaList',
        meta: {title: "Contas"},
        component: () => import('@pages/Conta/Conta.vue')
    },
    {
        path: 'conta/create',
        name: 'ContaCreate',
        meta: {title: "Cadastro de Conta"},
        component: () => import('@pages/Conta/ContaCreate.vue')
    },
    {
        path: 'conta/edit/:id',
        props: true,
        name: 'ContaEdit',
        meta: {title: "Edição de Conta"},
        component: () => import('@pages/Conta/ContaEdit.vue')
    },

    {
        path: 'convenio',
        name: 'ConvenioList',
        meta: {title: "Convênio Bancário"},
        component: () => import('@pages/Convenio/Convenio.vue')
    },
    {
        path: 'convenio/create',
        name: 'ConvenioCreate',
        meta: {title: "Cadastro de Convênio Bancário"},
        component: () => import('@pages/Convenio/ConvenioCreate.vue')
    },
    {
        path: 'convenio/edit/:id',
        props: true,
        name: 'ConvenioEdit',
        meta: {title: "Edição de Convênio Bancário"},
        component: () => import('@pages/Convenio/ConvenioEdit.vue')
    },

    {
        path: 'boleto',
        name: 'BoletoList',
        meta: {title: "Boleto Bancário"},
        component: () => import('@pages/Boleto/Boleto.vue')
    },
    {
        path: 'remessa/emitir',
        name: 'RemessaCreate',
        meta: {title: "Emitir Remessa"},
        component: () => import('@pages/Remessa/RemessaCreate.vue')
    },
    /*{
        path: 'convenio/edit/:id',
        props: true,
        name: 'ConvenioEdit',
        meta: {title: "Edição de Convênio Bancário"},
        component: () => import('@pages/Convenio/ConvenioEdit.vue')
    },*/

    {
        path: 'forma-pagamento',
        name: 'FormaPagamentoList',
        meta: {title: "Forma de Pagamentos"},
        component: () => import('@pages/FormaPagamento/FormaPagamento.vue')
    },
    {
        path: 'forma-pagamento/create',
        name: 'FormaPagamentoCreate',
        meta: {title: "Cadastro de Forma de Pagamento"},
        component: () => import('@pages/FormaPagamento/FormaPagamentoCreate.vue')
    },
    {
        path: 'forma-pagamento/edit/:id',
        props: true,
        name: 'FormaPagamentoEdit',
        meta: {title: "Edição de Forma de Pagamento"},
        component: () => import('@pages/FormaPagamento/FormaPagamentoEdit.vue')
    },

    {
        path: 'categoria',
        name: 'CategoriaList',
        meta: {title: "Categorias de Lançamentos"},
        component: () => import('@pages/Categoria/Categoria.vue')
    },
    {
        path: 'categoria/create',
        name: 'CategoriaCreate',
        meta: {title: "Cadastro de Categorias de Lançamentos"},
        component: () => import('@pages/Categoria/CategoriaCreate.vue')
    },
    {
        path: 'categoria/edit/:id',
        props: true,
        name: 'CategoriaEdit',
        meta: {title: "Edição de Categorias de Lançamentos"},
        component: () => import('@pages/Categoria/CategoriaEdit.vue')
    },
    {
        path: 'cfgcategoria',
        props: true,
        name: 'CfgCategoria',
        meta: {title: "Configuração de Categoria"},
        component: () => import('@pages/CfgCategoria/CfgCategoria.vue')
    },

    {
        path: 'conta-receber',
        name: 'ContaReceberList',
        meta: {title: "Contas a Receber"},
        component: () => import('@pages/ContaReceber/ContaReceber.vue')
    },
    {
        path: 'conta-receber/create',
        name: 'ContaReceberCreate',
        meta: {title: "Cadastro de Contas a Receber"},
        component: () => import('@pages/ContaReceber/ContaReceberCreate.vue')
    },
    {
        path: 'conta-receber/edit/:id',
        props: true,
        name: 'ContaReceberEdit',
        meta: {title: "Edição de Conta a Receber"},
        component: () => import('@pages/Lancamento/LancamentoContaEdit.vue')
    },

    {
        path: 'conta-pagar',
        name: 'ContaPagarList',
        meta: {title: "Contas a Pagar"},
        component: () => import('@pages/ContaPagar/ContaPagar.vue')
    },
    {
        path: 'conta-pagar/create',
        name: 'ContaPagarCreate',
        meta: {title: "Cadastro de Contas a Pagar"},
        component: () => import('@pages/ContaPagar/ContaPagarCreate.vue')
    },
    {
        path: 'conta-pagar/edit/:id',
        props: true,
        name: 'ContaPagarEdit',
        meta: {title: "Edição de Conta a Pagar"},
        component: () => import('@pages/Lancamento/LancamentoContaEdit.vue')
    },

    {
        path: 'lancamento',
        name: 'LancamentoList',
        meta: {title: "Lançamentos Financeiros"},
        component: () => import('@pages/Lancamento/Lancamento.vue')
    },
    {
        path: 'lancamento/edit/:id',
        props: true,
        name: 'LancamentoEdit',
        meta: {title: "Edição de Lançamentos Financeiros"},
        component: () => import('@pages/Lancamento/LancamentoEdit.vue')
    },
    {
        path: 'lancamento/show/:id',
        props: true,
        name: 'LancamentoShow',
        meta: {title: "Lançamentos Financeiros"},
        component: () => import('@pages/Lancamento/LancamentoShow.vue')
    },

    {
        path: 'caixa',
        name: 'Caixa',
        meta: {title: "Caixa"},
        component: () => import('@pages/Caixa/Caixa.vue')
    },
    {
        path: 'caixa/lancamento/:tipo',
        props: true,
        name: 'CaixaCreate',
        meta: {title: "Lançamento de Caixa"},
        component: () => import('@pages/Caixa/CaixaCreate.vue')
    },
]
