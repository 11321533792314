import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

import pessoasRoutes from './pessoas'
import cadastrosRoutes from './cadastros'
import administrativoRoutes from './administrativo'
import academicoRoutes from './academico'
import financeiroRoutes from './financeiro'
import professorRoutes from './professor'
import impressaoRoutes from './impressao'
import siteRoutes from './site'
import academicoTecnicoRoutes from './academicoTecnico'
import academicoAdmRoutes from './academicoAdm'

Vue.use(VueRouter)

const routes = [
    {
        path: "/sistema",
        meta: {requiresAuth: true},
        component: () => import('@templates/default/Index.vue'),
        children: [
            ...professorRoutes,
            ...pessoasRoutes,
            ...cadastrosRoutes,
            ...administrativoRoutes,
            ...financeiroRoutes,
            ...academicoAdmRoutes,
        ],

    },


    {
        path: "/",
        component: () => import('@templates/site/Index.vue'),
        children: [
            ...siteRoutes,
        ]
    },
    {
        path: "/impressao",
        component: () => import('@templates/default/Impressao.vue'),
        children: [
            ...impressaoRoutes,
        ]
    },
    {
        path: "/login",
        name: "Login",
        component: () => import('@pages/Auth/Login.vue'),
        //meta: {guest: true},
    },
    {
        path: '/nova-matricula',
        name: 'NovaMatricula',
        meta: {title: "Nova Matrícula"},
        component: () => import('@pages/NovaMatrícula/NovaMatricula.vue')
    },
    {
        path: '/nova-matricula/responsavel',
        props: true,
        name: 'NovaMatriculaResponsavel',
        meta: {title: "Nova Matrícula - Responsável"},
        component: () => import('@pages/NovaMatrícula/NovaMatriculaResponsavel.vue')
    },
    {
        path: '/nova-matricula/editar-responsavel/:id',
        props: true,
        name: 'EditarMatriculaResponsavel',
        meta: {title: "Editar Matrícula - Responsável"},
        component: () => import('@pages/NovaMatrícula/EditarMatriculaResponsavel.vue')
    },
    {
        path: '/nova-matricula/aluno/:responsavel_id',
        props: true,
        name: 'NovaMatriculaAluno',
        meta: {title: "Nova Matrícula - Aluno"},
        component: () => import('@pages/NovaMatrícula/NovaMatriculaAluno.vue')
    },
    {
        path: '/nova-matricula/aluno-cadastrado/:responsavel_id',
        props: true,
        name: 'AlunoCadastrado',
        meta: {title: "Alunos Cadastrados"},
        component: () => import('@pages/NovaMatrícula/AlunoCadastrado.vue')
    },
    {
        path: '/nova-matricula/curso-turma/:aluno_id',
        props: true,
        name: 'CursoTurma',
        meta: {title: "Matrícula - Curso e Turma"},
        component: () => import('@pages/NovaMatrícula/CursoTurma.vue')
    },
    {
        path: '/nova-matricula/info/:matricula_id',
        props: true,
        name: 'InformacaoDocumento',
        meta: {title: "Matrícula - Informações e Documentos"},
        component: () => import('@pages/NovaMatrícula/InformacaoDocumento.vue')
    },
    {
        path: "/recuperar-senha",
        name: "RecuperarSenha",
        component: () => import('@pages/Auth/RecuperarSenha.vue'),
    },
    {
        path: "/aluno-sequencia-online",
        name: "AlunoSequenciaOnline",
        component: () => import('@pages/AlunoSequenciaOnline/AlunoSequenciaOnline.vue'),
    },
    {
        path: "/aluno-sequencia-online/tipo-vaga",
        name: "AlunoSequenciaOnlineTipoVaga",
        component: () => import('@pages/AlunoSequenciaOnline/AlunoSequenciaOnlineTipoVaga.vue'),
    },
    {
        path: "/aluno-sequencia-online/cursos",
        name: "AlunoSequenciaOnlineCursos",
        component: () => import('@pages/AlunoSequenciaOnline/AlunoSequenciaOnlineCursos.vue'),
    },
    {
        path: "/aluno-sequencia-online/turmas",
        name: "AlunoSequenciaOnlineTurmas",
        component: () => import('@pages/AlunoSequenciaOnline/AlunoSequenciaOnlineTurmas.vue'),
    },
    {
        path: "/aluno-sequencia-online/arquivos",
        name: "AlunoSequenciaOnlineArquivos",
        component: () => import('@pages/AlunoSequenciaOnline/AlunoSequenciaOnlineArquivos.vue'),
    },
    {
        path: "/aluno-sequencia-online/confirmacao",
        name: "AlunoSequenciaOnlineConfirmacao",
        component: () => import('@pages/AlunoSequenciaOnline/AlunoSequenciaOnlineConfirmacao.vue'),
    },
    {
        path: "/aluno-sequencia-online/finalizar/:id",
        props: true,
        name: "AlunoSequenciaOnlineFinalizar",
        component: () => import('@pages/AlunoSequenciaOnline/AlunoSequenciaOnlineFinalizar.vue'),
    },
    {
        path: "/aluno-sequencia-online/comprovante-inscricao/:id",
        props: true,
        name: "AlunoSequenciaOnlineComprovanteInscricao",
        component: () => import('@pages/AlunoSequenciaOnline/AlunoSequenciaOnlineComprovanteInscricao.vue'),
    },

    {
        path: "/aluno-tecnico-sequencia-online",
        name: "AlunoTecnicoSequenciaOnline",
        component: () => import('@pages/AlunoTecnicoSequenciaOnline/AlunoTecnicoSequenciaOnline.vue'),
    },
    {
        path: "/aluno-tecnico-sequencia-online/tipo-vaga",
        name: "AlunoTecnicoSequenciaOnlineTipoVaga",
        component: () => import('@pages/AlunoTecnicoSequenciaOnline/AlunoTecnicoSequenciaOnlineTipoVaga.vue'),
    },
    {
        path: "/aluno-tecnico-sequencia-online/arquivos",
        name: "AlunoTecnicoSequenciaOnlineArquivos",
        component: () => import('@pages/AlunoTecnicoSequenciaOnline/AlunoTecnicoSequenciaOnlineArquivos.vue'),
    },
    {
        path: "/aluno-tecnico-sequencia-online/confirmacao",
        name: "AlunoTecnicoSequenciaOnlineConfirmacao",
        component: () => import('@pages/AlunoTecnicoSequenciaOnline/AlunoTecnicoSequenciaOnlineConfirmacao.vue'),
    },
    {
        path: "/aluno-tecnico-sequencia-online/finalizar/:id",
        props: true,
        name: "AlunoTecnicoSequenciaOnlineFinalizar",
        component: () => import('@pages/AlunoTecnicoSequenciaOnline/AlunoTecnicoSequenciaOnlineFinalizar.vue'),
    },
    {
        path: "/aluno-tecnico-sequencia-online/comprovante-inscricao/:id",
        props: true,
        name: "AlunoTecnicoSequenciaOnlineComprovanteInscricao",
        component: () => import('@pages/AlunoTecnicoSequenciaOnline/AlunoTecnicoSequenciaOnlineComprovanteInscricao.vue'),
    },

    {
        path: "/academico",
        component: () => import('@templates/default/IndexAcademico.vue'),
        children: [
            ...academicoTecnicoRoutes,
            ...academicoRoutes,
        ]
    },
]


const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
})

function loggedIn(){
   return store.state.isLogged;
}


const DEFAULT_TITLE = 'EEMAN - Gestão Escolar';
router.afterEach((to,from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
})

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!loggedIn()) {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            })
        }
        else {
            next()
        }
    }else if(to.matched.some(record => record.meta.guest)) {
        if (loggedIn()) {
            next({
                path: '/',
                query: { redirect: to.fullPath }
            })
        }
        else {
            next()
        }
    }else {
        next() // make sure to always call next()!
    }
})

export default router
