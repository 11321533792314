export default [
    {
        path: '',
        name: 'Home',
        component: () => import('@pages/Home/Home.vue')
    },
    {
        path: 'aluno',
        name: 'AlunoList',
        meta: {title: "Alunos"},
        component: () => import('@pages/Aluno/Aluno.vue')
    },
    {
        path: 'aluno/show/:id',
        props: true,
        name: 'AlunoShow',
        meta: {title: "Visualizar Aluno"},
        component: () => import('@pages/Aluno/AlunoShow.vue')
    },
    {
        path: 'aluno/create',
        props:true,
        name: 'AlunoCreate',
        meta: {title: "Cadastro de Aluno"},
        component: () => import('@pages/Aluno/AlunoCreate.vue')
    },
    {
        path: 'aluno/edit/:id',
        props: true,
        name: 'AlunoEdit',
        meta: {title: "Edição de Aluno"},
        component: () => import('@pages/Aluno/AlunoEdit.vue')
    },
    {
        path: 'responsavel/:id/:aluno_id',
        props: true,
        name: 'ResponsavelShow',
        meta: {title: "Visualizar Responsavel"},
        component: () => import('@pages/Responsavel/ResponsavelShow.vue')
    },
    {
        path: 'responsavel/create/:aluno_id',
        props: true,
        name: 'ResponsavelCreate',
        meta: {title: "Cadastro de Responsavel"},
        component: () => import('@pages/Responsavel/ResponsavelCreate.vue')
    },
    {
        path: 'responsavel/edit/:id',
        props: true,
        name: 'ResponsavelEdit',
        meta: {title: "Edição de Responsavel"},
        component: () => import('@pages/Responsavel/ResponsavelEdit.vue')
    },

    {
        path: 'professor',
        name: 'ProfessorList',
        meta: {title: "Professor"},
        component: () => import('@pages/Professor/Professor.vue')
    },
    {
        path: 'professor/show/:id',
        props: true,
        name: 'ProfessorShow',
        meta: {title: "Visualizar Professor"},
        component: () => import('@pages/Professor/ProfessorShow.vue')
    },
    {
        path: 'professor/create',
        name: 'ProfessorCreate',
        meta: {title: "Cadastro de Professor"},
        component: () => import('@pages/Professor/ProfessorCreate.vue')
    },
    {
        path: 'professor/edit/:id',
        props: true,
        name: 'ProfessorEdit',
        meta: {title: "Edição de Professor"},
        component: () => import('@pages/Professor/ProfessorEdit.vue')
    },

    {
        path: 'professor/cadastro/:id',
        props: true,
        name: 'ProfessorCadastro',
        meta: {title: "Meu Cadastro"},
        component: () => import('@pages/Professor/ProfessorCadastro.vue')
    },
    {
        path: 'professor/senha/:id',
        props: true,
        name: 'ProfessorSenhaEdit',
        meta: {title: "Alterar Senha"},
        component: () => import('@pages/Professor/ProfessorSenhaEdit.vue')
    },

    {
        path: 'funcionario',
        name: 'FuncionarioList',
        meta: {title: "Funcionários"},
        component: () => import('@pages/Funcionario/Funcionario.vue')
    },
    {
        path: 'funcionario/show/:id',
        props: true,
        name: 'FuncionarioShow',
        meta: {title: "Visualizar Funcionário"},
        component: () => import('@pages/Funcionario/FuncionarioShow.vue')
    },
    {
        path: 'funcionario/create',
        name: 'FuncionarioCreate',
        meta: {title: "Cadastro de Funcionário"},
        component: () => import('@pages/Funcionario/FuncionarioCreate.vue')
    },
    {
        path: 'funcionario/edit/:id',
        props: true,
        name: 'FuncionarioEdit',
        meta: {title: "Edição de Funcionário"},
        component: () => import('@pages/Funcionario/FuncionarioEdit.vue')
    },
    {
        path: 'funcionario/cadastro/:id',
        props: true,
        name: 'FuncionarioCadastro',
        meta: {title: "Meu Cadastro"},
        component: () => import('@pages/Funcionario/FuncionarioCadastro.vue')
    },
    {
        path: 'funcionario/senha/:id',
        props: true,
        name: 'FuncionarioSenhaEdit',
        meta: {title: "Alterar Senha"},
        component: () => import('@pages/Funcionario/FuncionarioSenhaEdit.vue')
    },

    {
        path: 'fornecedor',
        name: 'FornecedorList',
        meta: {title: "Fornecedores"},
        component: () => import('@pages/Fornecedor/Fornecedor.vue')
    },
    {
        path: 'fornecedor/show/:id',
        props: true,
        name: 'FornecedorShow',
        meta: {title: "Visualizar Fornecedor"},
        component: () => import('@pages/Fornecedor/FornecedorShow.vue')
    },
    {
        path: 'fornecedor/create',
        name: 'FornecedorCreate',
        meta: {title: "Cadastro de Fornecedor"},
        component: () => import('@pages/Fornecedor/FornecedorCreate.vue')
    },
    {
        path: 'fornecedor/edit/:id',
        props: true,
        name: 'FornecedorEdit',
        meta: {title: "Edição de Fornecedor"},
        component: () => import('@pages/Fornecedor/FornecedorEdit.vue')
    },

    {
        path: 'unidade',
        name: 'UnidadeList',
        meta: {title: "Unidades"},
        component: () => import('@pages/Unidade/Unidade.vue')
    },
    {
        path: 'unidade/show/:id',
        props: true,
        name: 'UnidadeShow',
        meta: {title: "Visualizar Unidade"},
        component: () => import('@pages/Unidade/UnidadeShow.vue')
    },
    {
        path: 'unidade/create',
        name: 'UnidadeCreate',
        meta: {title: "Cadastro de Unidade"},
        component: () => import('@pages/Unidade/UnidadeCreate.vue')
    },
    {
        path: 'unidade/edit/:id',
        props: true,
        name: 'UnidadeEdit',
        meta: {title: "Edição de Unidade"},
        component: () => import('@pages/Unidade/UnidadeEdit.vue')
    },
]
