export default [
    {
        path: 'calendarioeventos',
        name: 'CalendarioEventos',
        meta: {title: "Calendário de Eventos"},
        component: () => import('@pages/Comunicacao/CalendarioEventos.vue')
    },
    {
        path: 'eventos',
        name: 'CalendarioEventoList',
        meta: {title: "Eventos"},
        component: () => import('@pages/Comunicacao/Evento.vue')
    },
    {
        path: 'periodo-letivo/finalizar',
        name: 'PeriodoLetivoFinalizar',
        meta: {title: "Finalizar de Período Letivo"},
        component: () => import('@pages/PeriodoLetivo/PeriodoLetivoFinalizar.vue')
    },
    {
        path: 'periodo-letivo/finalizar-tecnico',
        name: 'PeriodoLetivoFinalizarTecnico',
        meta: {title: "Finalizar de Período Letivo"},
        component: () => import('@pages/PeriodoLetivo/PeriodoLetivoFinalizarTecnico.vue')
    },
    {
        path: 'gerenciamento-ano',
        name: 'GerenciamentoAnoList',
        meta: {title: "Gerenciamento de Ano Letivo"},
        component: () => import('@pages/GerenciamentoAno/GerenciamentoAno.vue')
    },
    {
        path: 'gerenciamento-ano/create',
        name: 'GerenciamentoAnoCreate',
        meta: {title: "Cadastro de Gerenciamento de Ano Letivo"},
        component: () => import('@pages/GerenciamentoAno/GerenciamentoAnoCreate.vue')
    },
    {
        path: 'gerenciamento-ano/edit/:id',
        props: true,
        name: 'GerenciamentoAnoEdit',
        meta: {title: "Edição de Gerenciamento de Ano Letivo"},
        component: () => import('@pages/GerenciamentoAno/GerenciamentoAnoEdit.vue')
    },
    {
        path: 'rematricula',
        name: 'RematriculaListAdm',
        meta: {title: "Rematrícula"},
        component: () => import('@pages/Rematricula/RematriculaList.vue')
    },
    {
        path: 'rematricula/create',
        name: 'RematriculaCreateAdm',
        meta: {title: "Criar Rematrícula"},
        component: () => import('@pages/Rematricula/RematriculaCreate.vue')
    },
    {
        path: 'rematricula/edit',
        props: true,
        name: 'RematriculaEditAdm',
        meta: {title: "Criar Rematrícula"},
        component: () => import('@pages/Rematricula/RematriculaEdit.vue')
    },
    {
        path: 'eventos/create',
        name: 'CalendarioEventoCreate',
        meta: {title: "Cadastro de Evento"},
        component: () => import('@pages/Comunicacao/EventoCreate.vue')
    },
    {
        path: 'eventos/edit/:id',
        props: true,
        name: 'CalendarioEventoEdit',
        meta: {title: "Edição de Evento"},
        component: () => import('@pages/Comunicacao/EventoEdit.vue')
    },
    {
        path: 'comunicados',
        name: 'ComunicadoList',
        meta: {title: "Comunicados"},
        component: () => import('@pages/Comunicacao/Comunicado.vue')
    },
    {
        path: 'comunicados/create',
        name: 'ComunicadoCreate',
        meta: {title: "Cadastro de Comunicado"},
        component: () => import('@pages/Comunicacao/ComunicadoCreate.vue')
    },
    {
        path: 'comunicados/edit/:id',
        props: true,
        name: 'ComunicadoEdit',
        meta: {title: "Edição de Comunicado"},
        component: () => import('@pages/Comunicacao/ComunicadoEdit.vue')
    },
    {
        path: 'comunicados/show/:id',
        props: true,
        name: 'ComunicadoShow',
        meta: {title: "Visualização de Comunicado"},
        component: () => import('@pages/Comunicacao/ComunicadoShow.vue')
    },
    {
        path: 'comunicados/envio/:id',
        props: true,
        name: 'ComunicadoEnvio',
        meta: {title: "Enviar Comunicado"},
        component: () => import('@pages/Comunicacao/ComunicadoSelecao.vue')
    },
    {
        path: 'comunicados/confirmacao/:id',
        props: true,
        name: 'ComunicadoConfirmacao',
        meta: {title: "Enviar Comunicado"},
        component: () => import('@pages/Comunicacao/ComunicadoConfirmacao.vue')
    },
    {
        path: 'comunicados/confirmacao-professor/:id',
        props: true,
        name: 'ComunicadoConfirmacaoProfessor',
        meta: {title: "Enviar Comunicado"},
        component: () => import('@pages/Comunicacao/ComunicadoConfirmacaoProfessor.vue')
    },
    {
        path: 'bolsa',
        name: 'BolsaList',
        meta: {title: "Bolsas"},
        component: () => import('@pages/Bolsa/Bolsa.vue')
    },
    {
        path: 'bolsa/create',
        name: 'BolsaCreate',
        meta: {title: "Cadastro de Bolsa"},
        component: () => import('@pages/Bolsa/BolsaCreate.vue')
    },
    {
        path: 'bolsa/edit/:id',
        props: true,
        name: 'BolsaEdit',
        meta: {title: "Edição de Bolsa"},
        component: () => import('@pages/Bolsa/BolsaEdit.vue')
    },

    {
        path: 'sala',
        name: 'SalaList',
        meta: {title: "Salas"},
        component: () => import('@pages/Sala/Sala.vue')
    },
    {
        path: 'sala/create',
        name: 'SalaCreate',
        meta: {title: "Cadastro de Sala"},
        component: () => import('@pages/Sala/SalaCreate.vue')
    },
    {
        path: 'sala/edit/:id',
        props: true,
        name: 'SalaEdit',
        meta: {title: "Edição de Sala"},
        component: () => import('@pages/Sala/SalaEdit.vue')
    },
    {
        path: 'sala/show/:id',
        props: true,
        name: 'SalaShow',
        meta: {title: "Visualização de Sala"},
        component: () => import('@pages/Sala/SalaShow.vue')
    },

    {
        path: 'patrimonio',
        name: 'PatrimonioList',
        meta: {title: "Patrimonios"},
        component: () => import('@pages/Patrimonio/Patrimonio.vue')
    },
    {
        path: 'patrimonio/create',
        name: 'PatrimonioCreate',
        meta: {title: "Cadastro de Patrimonio"},
        component: () => import('@pages/Patrimonio/PatrimonioCreate.vue')
    },
    {
        path: 'patrimonio/edit/:id',
        props: true,
        name: 'PatrimonioEdit',
        meta: {title: "Edição de Patrimonio"},
        component: () => import('@pages/Patrimonio/PatrimonioEdit.vue')
    },
    {
        path: 'patrimonio/show/:id',
        props: true,
        name: 'PatrimonioShow',
        meta: {title: "Visualização de Patrimonio"},
        component: () => import('@pages/Patrimonio/PatrimonioShow.vue')
    },

    {
        path: 'patrimoniotipo',
        name: 'PatrimonioTipoList',
        meta: {title: "Tipos"},
        component: () => import('@pages/PatrimonioTipo/Tipo.vue')
    },
    {
        path: 'patrimoniotipo/create',
        name: 'PatrimonioTipoCreate',
        meta: {title: "Cadastro de Tipos"},
        component: () => import('@pages/PatrimonioTipo/TipoCreate.vue')
    },
    {
        path: 'patrimoniotipo/edit/:id',
        props: true,
        name: 'PatrimonioTipoEdit',
        meta: {title: "Edição de Tipos"},
        component: () => import('@pages/PatrimonioTipo/TipoEdit.vue')
    },

    {
        path: 'calendario',
        name: 'Calendario',
        meta: {title: "Calendarios"},
        component: () => import('@pages/Calendario/Calendario.vue')
    },

    {
        path: 'evento',
        name: 'EventoList',
        meta: {title: "Eventos"},
        component: () => import('@pages/Evento/Evento.vue')
    },
    {
        path: 'evento/create',
        name: 'EventoCreate',
        meta: {title: "Cadastro de Evento"},
        component: () => import('@pages/Evento/EventoCreate.vue')
    },
    {
        path: 'evento/edit/:id',
        props: true,
        name: 'EventoEdit',
        meta: {title: "Edição de Evento"},
        component: () => import('@pages/Evento/EventoEdit.vue')
    },
]
