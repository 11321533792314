require('./bootstrap');

window.Vue = require('vue').default;

import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import router from './router/router'

import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
//import VueMask from 'v-mask'
import VueTheMask from 'vue-the-mask'
import money from  'vuejs-money'
import store from './store/store'
import moment from 'moment'
//import VueCompositionAPI from '@vue/composition-api'
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

Vue.use(Vuetify)
Vue.use(VueTheMask)
Vue.use(money)
//Vue.use(VueCompositionAPI)


Vue.use(money, {
    decimal: ',',
    thousands: '.',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false
})

const opts = {
    icons: {
        //iconfont: 'mdi'
        iconfont: 'md' || 'fa'
    },
    theme: {
        themes: {
            light: {
                primary: '#0074A0',
                secondary: '#008ABF',
                accent: '#D6E7ED',
                error: '#b71c1c',
            },
        },
    },
}

Vue.component('template-app', require('@/App.vue').default);
Vue.component('infobox', require('@components/InfoBox').default);


Vue.prototype.$maskCpf = "###.###.###-##"
Vue.prototype.$maskCnpj = "##.###.###/####-##"
Vue.prototype.$maskTime = "##:##"
Vue.prototype.$maskCep = "#####-###"
Vue.prototype.$maskTelefone = "(##) #####-####"
Vue.prototype.$moment = moment

Vue.prototype.$getFormData = function(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

Vue.filter('formatYear', function(value) {
    if (value) {
        return moment(String(value)).format('YYYY')
    }
});

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});
Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY - HH:mm')
    }
});
Vue.filter('formatTime', function(value) {
    if (value) {
        return moment(String(value), "HH:mm:ss").format('HH:mm')
    }
});
Vue.filter('formatMoney', function(value, symbol = 'R$', digits = 2) {
    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });

    if(symbol !== '')symbol = symbol+' ';

    return symbol + formatter.format(value);
});

Vue.filter('qtAnos', function(value) {
    let res = 0
    let dataInicial = new Date(value);
    let dataAtual = new Date();
    let diferencaMilissegundos = dataAtual - dataInicial;

    let milissegundosPorAno = 1000 * 60 * 60 * 24 * 365.25;
    let diferencaAnos = diferencaMilissegundos / milissegundosPorAno;

    res = Math.floor(diferencaAnos);
    return res
});

Vue.filter('situacaoPatrimonio', function(value) {
    let res = ''
    switch(value){
        case 1 : res = "Em uso"; break;
        case 2 : res = "Quebrado"; break;
        case 3 : res = "Emprestado"; break;
    }
    return res
});
Vue.filter('motivoCancelamento', function(value) {
    let res = ''
    switch(value){
        case 1 : res = "Transferência"; break;
        case 2 : res = "Deixou de Frequentar"; break;
        case 3 : res = "Falecimento"; break;
    }
    return res
});
Vue.filter('matriculaSituacao', function(value) {
    let res = ''
    switch(value){
        case 1 : res = "Aguardando"; break;
        case 2 : res = "Ativa"; break;
        case 3 : res = "Cancelada"; break;
        case 4 : res = "Finalizada"; break;
        case 5 : res = "Trancada"; break;
    }
    return res
});

Vue.filter('grauParentesco', function(value) {
    let res = ''
    switch(value){
        case 1 : res = "Filho/Filha"; break;
        case 2 : res = "Cônjuge"; break;
        case 3 : res = "Pai/Mãe"; break;
        case 4 : res = "Avô/Avó"; break;
        case 5 : res = "Bisavô/Bisavó"; break;
        case 6 : res = "Sobrinho/Sobrinha"; break;
        case 7 : res = "Tio/Tia"; break;
        case 8 : res = "Neto/Neta"; break;
        case 9: res = "Sogro/Sogra"; break;
        case 10 : res = "Genro/Nora"; break;
        case 11 : res = "Enteado/Enteada"; break;
        case 12 : res = "Irmão/Irmã"; break;
        case 13 : res = "Filho(a) Adotivo(a)"; break;
        case 14 : res = "Outros"; break;
        case 15 : res = "Menor Sob Tutela"; break;
    }
    return res
});

Vue.filter('sexoFiltro', function(value) {
    let res = ''
    switch(value){
        case 1 : res = "Masculino"; break;
        case 2 : res = "Feminino"; break;
        case 3 : res = "Outro"; break;
        case 4 : res = "Prefiro não informar"; break;
    }
    return res
});
Vue.filter('documentoSituacao', function(value) {
    let res = ''
    switch(value){
        case 1 : res = "Aguardando"; break;
        case 2 : res = "Entregue"; break;
        case 3 : res = "Dispensado"; break;
    }
    return res
});

Vue.filter('dayOfWeek', function(value) {

    let text = ''
    switch (value) {
        case 0 : text = 'domingo'; break;
        case 1 : text = 'segunda-feira'; break;
        case 2 : text = 'terça-feira'; break;
        case 3 : text = 'quarta-feira'; break;
        case 4 : text = 'quinta-feira'; break;
        case 5 : text = 'sexta-feira'; break;
        case 6 : text = 'sábado'; break;
    }
    return text

});

Vue.mixin({
    methods: {
        idadeMeses: function (value) {

            let y = Math.floor(value / 12)
            let m = (value % 12)
            let c = (y > 0 && m > 0) ? ' e ' : ''

            let years;
            let months;

            switch (y){
                case 0 : years = ''; break;
                case 1 : years = y + ' ano'; break;
                default : years = y + ' anos'; break;
            }
            switch (m){
                case 0 : months = ''; break;
                case 1 : months = m + ' mês'; break;
                default : months = m + ' meses'; break;
            }

            return years + c + months
        },
        faixaIdadeMeses: function (min, max) {
            let minimo = this.idadeMeses(min)
            let maximo = this.idadeMeses(max)
            let c = (min > 0 && max > 0) ? ' a ' : ''

            let txMin = (minimo) ? minimo : 'até '
            let txMax = (maximo) ? maximo : ' acima'

            if(!min && !max){
                return '--'
            }

            return txMin + c + txMax
        },
        horariosPeriodoLetivo: function(horarios, periodo_letivo_id) {
            return horarios.filter(item => item.periodo_letivo_id == periodo_letivo_id);
        }
    },
})

Vue.directive('focus', {
    inserted: function (el) {
        binding.value
    }
})

const app = new Vue({
    el: '#app',
    router,
    store,
    vuetify: new Vuetify(opts),
});
